export default [
  {
    header: 'Groupes',
    rights: [
      'GROUPES:read'
    ]
  },
  {
    title: 'Gérer les groupes',
    icon: 'UsersIcon',
    route: 'groupes',
    rights: [
      'GROUPES:read'
    ]
  }
]