export default [
  {
    header: 'Changelog',rights: [
      'PARAMETRES:admin'
    ]
  },
  {
    title: 'Gérer les changelogs',
    icon: 'CalendarIcon',
    route: 'manage-changelogs',
    rights: [
      'PARAMETRES:admin'
    ],
  },
  {
    title: 'Gérer les types de changelog',
    icon: 'CalendarIcon',
    route: 'changelog-types',
    rights: [
      'PARAMETRES:admin',
    ]
  },
]