export default [
  {
    header: 'Compétences',
    rights: [
      'INACCESSIBLE:read'
    ]
  },
  {
    title: 'Gérer les compétences',
    icon: 'SlackIcon',
    route: 'competences',
    rights: [
      'INACCESSIBLE:read'
    ]
  }
]