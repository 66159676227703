<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
     <!-- <li data-v-6be03386="" class="navigation-header text-truncate">
      <span>Avis</span>
    </li>
    <li class="nav-item">
      <a v-b-modal.avis-modal class="d-flex align-items-center">
        <span class="menu-title text-truncate">
          <feather-icon icon="ThumbsUpIcon" size="18" /> Donner mon avis</span
        ></a
      >
    </li>-->
    <li v-if="canCreateInfoAdd" data-v-6be03386="" class="navigation-header text-truncate">
      <span>Informations additionnelles</span
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-more-horizontal"
      >
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
    </li>

    <li v-if="canCreateInfoAdd" class="nav-item">
      <a v-b-modal.add-new-basic-information class="d-flex align-items-center">
        <span class="menu-title text-truncate">
          <feather-icon icon="InfoIcon" size="18" /> Ajouter une information additionnelle
        </span>
      </a>
    </li> 
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import { BButton } from "bootstrap-vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  computed: {
    canCreateInfoAdd () {
      return this.$can('FOURNISSEURS_INFOS_ADD:create') || 
        this.$can('PARTENAIRES_INFOS_ADD:create') || 
        this.$can('ENTREPRISES_INFOS_ADD:create') || 
        this.$can('UTILISATEURS_INFOS_ADD:create')
    }
  }
};
</script>
<style lang="scss" scoped>
li {
  position: relative;
  white-space: nowrap;
}

.nav-item {
  font-size: 1.1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 400;
}
</style>