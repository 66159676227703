export default [
  {
    header: 'Utilisateurs',
    rights: [
      'UTILISATEURS:read'
    ]
  },
  {
    title: 'Gérer les utilisateurs',
    icon: 'UserIcon',
    route: 'utilisateurs',
    rights: [
      'UTILISATEURS:read'
    ]
    // rights: [
    //   'USERS:read'
    // ]
    // children: [
    //   {
    //     title: 'Rechercher un dossier',
    //     route: 'accompagnement-individuel',
    //     rights: [
    //       "ADMINISTRATION"
    //     ],
    //   },
    //   {
    //     title: 'Nouveau dossier',
    //     route: 'create-accompagnement-individuel',
    //     rights: [
    //       "ADMINISTRATION"
    //     ],
    //   },
    //   {
    //     title: 'Liste des dossiers',
    //     route: 'list-accompagnement-individuel',
    //     rights: [
    //       "ADMINISTRATION"
    //     ],
    //   },
    // ],
  },
  // {
  //   title: 'Collectifs',
  //   icon: 'BriefcaseIcon',
  //   children: [
  //     {
  //       title: 'Rechercher une entreprise',
  //       route: 'search-accompagnement-collectif',
  //       rights: [
  //         "ACTIONS_COLLECTIVESs"
  //       ],
  //     },
  //   ],
  //   rights: [
  //     "ACTIONS_COLLECTIVESs"
  //   ],
  // },
]