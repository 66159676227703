export default [
  {
    header: 'export',
    rights: [
      'EXPORTS:read'
    ]
  },
  {
    title: 'Export de données',
    icon: 'DownloadIcon',
    route: 'export',
    rights: [
      'EXPORTS:read'
    ]
  }
]