export default [
  {
    header: 'Partenaires FASTT',
    rights: [
      'PARTENAIRES_FASTT:read',
      'TYPES_PARTENAIRES_FASTT:read'
    ]
  },
  {
    title: 'Validation',
    icon: 'Share2Icon',
    route: 'validation-partenaires-fastt',
    rights: [
      'PARTENAIRES_FASTT:admin'
    ]
  },
  {
    title: 'Gérer les types',
    icon: 'SettingsIcon',
    route: 'types-partenaires-fastt',
    rights: [
      'TYPES_PARTENAIRES_FASTT:read'
    ]
  },
  {
    title: 'Gérer les partenaires FASTT',
    icon: 'Share2Icon',
    route: 'partenaires-fastt',
    rights: [
      'PARTENAIRES_FASTT:read'
    ]
  },
]