/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import administration from './administration'
import utilisateurs from './utilisateurs'
import equipes from './equipes'
import adherents from './adherents'
import partenaires from './partenaires'
import partenairesFASTT from './partenairesFASTT'
import trombinoscope from './trombinoscope'
import exportdonnee from './export'
import fournisseurs from './fournisseurs'
import competences from './competences'
import groupes from './groupes'
import plannings from './plannings'
import fichesIncident from './fiches-incident'
import processus from './processus'
import changelogs from './changelogs'


// Array of sections
export default [
  ...dashboard, 
  ...adherents,
  ...utilisateurs, 
  ...fichesIncident,
  ...plannings,
  ...equipes,
  ...processus,
  ...groupes,
  ...competences,
  // ...appsAndPages, 
  // ...uiElements, 
  // ...formAndTable, 
  // ...chartsAndMaps, 
  // ...others, 
  ...partenaires,
  ...partenairesFASTT,
  ...fournisseurs,
  ...trombinoscope,
  ...exportdonnee,
  ...administration,
  ...changelogs
]
