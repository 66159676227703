export default [
  {
    header: 'Planning',rights: [
      'PLANNING_ADMIN:admin',
      'PLANNING_SUPERADMIN:admin',
      'PLANNING_SALARIE:admin'
    ]
  },
  {
    title: 'Gérer les plannings',
    icon: 'CalendarIcon',
    route: 'plannings',
    rights: [
      'PLANNING_ADMIN:admin',
      'PLANNING_SUPERADMIN:admin'
    ],
  },
  {
    title: 'Visualiser les plannings',
    icon: 'CalendarIcon',
    route: 'plannings',
    rights: [
      'PLANNING_SALARIE:admin',
    ],
    notRights: [
      'PLANNING_ADMIN:admin',
      'PLANNING_SUPERADMIN:admin'
    ]
  },
  {
    title: 'Export des plannings des adhérents',
    icon: 'UploadIcon',
    route: 'export-planning-adherent',
    rights: [
      'PLANNING_ADMIN:admin',
      'PLANNING_SUPERADMIN:admin'
    ]
  },
  {
    title: 'Demandes de modifications',
    icon: 'Edit3Icon',
    route: 'demandes-modifications-plannings',
    rights: [
      'PLANNING_ADMIN:admin',
      'PLANNING_SUPERADMIN:admin'
    ]
  },
  {
    title: 'Administration planning',
    icon: 'UserIcon',
    rights: [
      "PLANNING_ADMIN:admin",
      "PLANNING_SUPERADMIN:admin"
    ],
    children: [
      {
        title: 'Gérer les types de créneau',
        icon: 'ListIcon',
        route: 'types-creneau',
        rights: [
          'PLANNING_ADMIN:admin',
          'PLANNING_SUPERADMIN:admin'
        ]
      },
      {
        title: 'Gérer les modèles d\'export de planning',
        icon: 'LayoutIcon',
        route: 'export-planning-adherent-modele',
        rights: [
          'PLANNING_ADMIN:admin',
          'PLANNING_SUPERADMIN:admin'
        ]
      },
      {
        title: 'Gérer les simulations',
        icon: 'LayersIcon',
        route: 'simulations-plannings',
        rights: [
          'PLANNING_ADMIN:admin',
          'PLANNING_SUPERADMIN:admin'
        ]
      },
      {
        title: 'Gérer les structures',
        icon: 'GridIcon',
        route: 'structures',
        rights: [
          'PLANNING_ADMIN:admin',
          'PLANNING_SUPERADMIN:admin'
        ]
      },
    ],
  },
]