export default [
  {
    title: 'Gérer les adhérents',
    icon: 'BriefcaseIcon',
    route: 'adherents',
  },
    // {
    //   header: 'Adhérents',
    //   icon: 'BriefcaseIcon',
    //   rights: [
    //     "ADMINISTRATION"
    //   ],
    //   children: [
    //     {
    //       title: 'Gérer les adhérents',
    //       icon: 'SearchIcon',
    //       route: 'adherents',
    //       rights: [
    //         "ADMINISTRATION"
    //       ],
    //     },
    //   ],
    // },
  ]
  