export default [
    {
      header: 'Processus',
      rights: [
        'FI_PROCESSUS:read'
      ]
    },
    {
      title: 'Gérer les processus',
      icon: 'BoxIcon',
      route: 'processus',
      rights: [
        'FI_PROCESSUS:read'
      ]
    }
]