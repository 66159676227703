export default [
  {
    header: 'Administration',
    rights: [
      'PARAMETRES:read',
      'FONCTIONS:read'
    ]
  },
  {
    title: 'Fonctions et droits',
    icon: 'LockIcon',
    route: 'admin-autorisations',
    rights: [
      'FONCTIONS:read'
    ]
  },
  {
    title: 'Paramètres',
    icon: 'SettingsIcon',
    route: 'admin-parametres',
    rights: [
      'PARAMETRES:read'
    ]
  },
  {
    title: 'Gérer les avis',
    icon: 'SettingsIcon',
    route: 'gestion-avis',
    rights: [
      'AVIS:read'
    ]
  },
  {
    title: 'Gérer les accès BI',
    icon: 'SettingsIcon',
      route: 'bi-acces',
      rights: [
        'ACCES_BI:read'
      ]
  },
]
