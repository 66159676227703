export default [
  {
    header: 'trombinoscope',
    rights: [
      'UTILISATEURS:read'
    ]
  },
  {
    title: 'Trombinoscope',
    icon: 'CameraIcon',
    route: 'trombinoscope',
    rights: [
      'UTILISATEURS:read'
    ]
  }
]