export default [
  {
    header: 'Adherents',
    rights: [
      'ENTREPRISES:read',
      'EXTERNES_ENTREPRISES_OPENSOME:read'
    ]
  },
  {
    title: 'Gérer les adhérents',
    icon: 'BriefcaseIcon',
    route: 'adherents',
    rights: [
      'ENTREPRISES:read',
      'EXTERNES_ENTREPRISES_OPENSOME:read'
    ]
  },
]