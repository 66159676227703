export default [
  {
    header: 'Partenaires',
    rights: [
      'PARTENAIRES:read'
    ]
  },
  {
    title: 'Gérer les partenaires',
    icon: 'Share2Icon',
    route: 'partenaires',
    rights: [
      'PARTENAIRES:read'
    ]
  },
]