export default [
  {
    header: 'Fournisseurs',
    rights: [
      'FOURNISSEURS:read'
    ]
  },
  {
    title: 'Gérer les fournisseurs',
    icon: 'UserIcon',
    route: 'fournisseurs',
    rights: [
      'FOURNISSEURS:read'
    ]
  },
]