export default [
  {
    header: 'Fiches incident',
    rights: [
      'FICHES_INCIDENT:read',
    ]
  },
  {
    title: "Liste des fiches",
    icon: "FileTextIcon",
    route: "fiches-incident",
    rights: [
      'FICHES_INCIDENT:read',
    ]
  },
  {
    title: "Déposer une fiche",
    icon: "FilePlusIcon",
    route: "fiches-incident-create",
    rights: [
      'FICHES_INCIDENT:create'
    ],
  },
];
