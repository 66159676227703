export default [
  {
    header: 'Administration',
    icon: 'SettingsIcon',

    children: [
      // {
      //   title: 'Gérer les options',
      //   icon: 'ListIcon',

      //   children: [
      //     {
      //       title: 'Types de contrat',
      //       route: 'admin-options-type-contrat',
      //       rights: [
      //         'ADMINISTRATION',
      //       ],
      //     },
      //     {
      //       title: 'Domaines',
      //       route: 'admin-options-domaines',
      //       rights: [
      //         'ADMINISTRATION',
      //       ],
      //     },
      //     {
      //       title: 'Situations familiales',
      //       route: 'admin-options-situations-familiales',
      //       rights: [
      //         'ADMINISTRATION',
      //       ],
      //     },
      //     {
      //       title: 'Catégories socio-professionnelles',
      //       route: 'admin-options-categories-socio-pro',
      //       rights: [
      //         'ADMINISTRATION',
      //       ],
      //     },
      //     {
      //       title: 'Horaires',
      //       route: 'admin-options-horaires',
      //       rights: [
      //         'ADMINISTRATION',
      //       ],
      //     },
      //   ],
      // },
      // {
      //   title: 'Gérer les entreprises',
      //   icon: 'BriefcaseIcon',
      //   route: 'admin-entreprises',
      //   rights: [
      //     'ADMINISTRATION',
      //   ],
      // },
      // {
      //   title: 'Gérer les utilisateurs',
      //   icon: 'UserIcon',
      //   route: 'admin-profils',
      //   rights: [
      //     'ADMINISTRATION',
      //   ],
      // },
      {
        title: 'Fonctions et droits',
        icon: 'LockIcon',
        route: 'admin-autorisations',
      },
      // {
      //   title: 'Gérer les équipes',
      //   icon: 'UsersIcon',
      //   route: 'admin-equipes',
      //   rights: [
      //     'ADMINISTRATION',
      //   ],
      // },
    ],
  },
]
