export default [
    {
      header: 'Equipes',
      rights: [
        'EQUIPES:read'
      ]
    },
    {
      title: 'Gérer les équipes',
      icon: 'UsersIcon',
      route: 'equipes',
      rights: [
        'EQUIPES:read'
      ]
    }
]