<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.sstrn.com"
        target="_blank"
      >SSTRN</b-link>
      <span class="d-none d-sm-inline-block">, Tous droits reservés</span>
    </span>

    <span class="float-md-right d-none d-md-block">Fait main et avec passion
      <feather-icon
        @click="skin = isDark ? 'light' : 'dark'"
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  methods: {
    changeDark: function (){
      this.skin = isDark ? 'light' : 'dark'
    }
  }
}
</script>
